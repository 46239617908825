import { App_connection } from 'views/app/app_connection';
import { Activity } from 'views/app/activity/index';
import { Pivot } from 'views/app/explore/index';
import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { Restore } from 'views/app/activity/restore';
import { RestoreDetails } from 'views/app/activity/restoreDetails';
import { RestoreHistory } from 'views/app/activity/restoreHistory';
import { Details } from 'views/app/activity/details';
import { Details as CleanupDetails } from 'views/app/cleanup/details';
import { Analysis } from 'views/app/activity/analysis';
import { Cleanup } from 'views/app/cleanup/index';
import { Rules } from 'views/app/cleanup/rules';
import { RuleDetails } from 'views/app/cleanup/ruleDetails';
import { Chat } from 'views/app/activity/chat';
import {Error } from 'views/app/error';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/app/error',
    view: Error,
    layout: 'app',
    permission: 'user',
    title: 'Error'
  },
  {
    path: '/app/app_connection',
    view: App_connection,
    layout: 'app',
    permission: 'user',
    title: 'Connections'
  },
  {
    path: '/app/cleanup/index',
    view: Cleanup,
    layout: 'app',
    permission: 'user',
    title: 'Clean-Up'
  },
  {
    path: '/app/cleanup/rules',
    view: Rules,
    layout: 'app',
    permission: 'user',
    title: 'Monitors'
  },
  {
    path: '/app/cleanup/ruleDetails/:id',
    view: RuleDetails,
    layout: 'app',
    permission: 'user',
    title: 'Rule Details'
  },
  {
    path: '/app/cleanup/ruleDetails',
    view: RuleDetails,
    layout: 'app',
    permission: 'user',
    title: 'Create Monitor'
  },
  {
    path: '/app/cleanup/details/:id',
    view: CleanupDetails,
    layout: 'app',
    permission: 'user',
    title: 'Record Details'
  },
  {
    path: '/app/activity/index',
    view: Activity,
    layout: 'app',
    permission: 'user',
    title: 'Activity'
  },
  {
    path: '/app/activity/restore',
    view: Restore,
    layout: 'app',
    permission: 'user',
    title: 'Restore'
  },
  {
    path: '/app/activity/restoreHistory',
    view: RestoreHistory,
    layout: 'app',
    permission: 'user',
    title: 'Restore History'
  },
  {
    path: '/app/activity/restoreDetails/:id',
    view: RestoreDetails,
    layout: 'app',
    permission: 'user',
    title: 'Restore Details'
  },
  {
    path: '/app/activity/details/:id',
    view: Details,
    layout: 'app',
    permission: 'user',
    title: 'Record Details'
  },
  {
    path: '/app/activity/analysis',
    view: Analysis,
    layout: 'app',
    permission: 'user',
    title: 'AI Assistant'
  },
  {
    path: '/app/explore/index',
    view: Pivot,
    layout: 'app',
    permission: 'user',
    title: 'Explore'
  },
  {
    path: '/ai',
    view: Chat,
    layout: 'app',
    permission: 'user',
    title: 'AI Assistant'
  },
]

export default Routes;
